<template>
  <Toolbar>
    <template #left>
      <h2 class="_heading-2" v-t="'settings_page.title.logotype'" />
    </template>

    <template #right>
      <FileUpload name="file" mode="basic" :auto="true" :customUpload="true" @uploader="upload" :maxFileSize="8000000" chooseLabel="Add logo" />
    </template>
  </Toolbar>
  <div class="grid mt-4 _relative">
    <ImgPreview v-for="item in logos.data" :key="item.id" col="col-3" :url="item.url" :callback="() => deleteBg(item.id)" />
    <Loader isOverlay v-if="$store.state.settings.bg.isLoading" />
  </div>

</template>

<script>
import { useStore } from 'vuex'
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';

import { ImgPreview, Loader } from '../../components/ui'

import { Icon } from '../../components/ui'

export default {
  components: {
    Toolbar,
    FileUpload,
    ImgPreview,
    Loader,
    // Icon,
  },
  setup() {
    const store = useStore()
    const logos = store.state.settings.logos

    async function upload(params) {
      const body = {
        photo: params.files[0]
      }
      store.dispatch('settings/submitBase', { model: 'logos', endpoint: 'companyLogos', body})
    }

    async function deleteBg(params) {
      store.dispatch('settings/deleteBase', { model: 'logos', endpoint: 'companyLogo', logo: params })
    }

    return {
      logos,
      upload,
      deleteBg,
    }
  }
}
</script>

<style lang="scss" module>
</style>